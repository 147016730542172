/* General container styling */

.container {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(0, 0, 0);
  /* background-color: white; */
  border-radius: 1px;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  transition: all 0.3s ease;
}
.logo h2 {
  color: #c0c1c2;
}

/* Logo styling */
.logo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1em;
}

.logo img {
  height: 88px;
  margin-right: 10px;
  width: 140px;
}

/* Menu toggle button (for mobile view) */
.menu-toggle {
  display: none;
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.menu-toggle .bar {
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin: 4px 0;
  transition: all 0.3s ease;
}

/* Menu styling */
.menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 900;
}

.menu li {
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
}

.menu a {
  text-decoration: none;
  color: #e4e4e4;
  font-weight: bold;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.menu a:hover {
  color: #007bff;
}

/* Dropdown menu for services */
.services-dropdown {
  position: relative;
}

.floating-submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(96, 187, 248, 0.979);

  padding: 3px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: none;
  border-radius: 5px;
  width: 220px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.floating-submenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.floating-submenu li {
  padding: 4px 0;
  border-bottom: 1px solid #dbdada;
}

.floating-submenu li:last-child {
  border-bottom: none;
}

.floating-submenu a {
  color: #222222;
  text-decoration: none;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.floating-submenu a:hover {
  color: #007bff;
}

/* Show the floating submenu on hover */
.services-dropdown:hover .floating-submenu {
  display: block;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .navbar {
    padding: 10px 20px;
  }

  .menu {
    font-size: 0.9rem;
  }

  .menu a {
    padding: 5px 8px;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
  }

  .menu {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #000000;
    flex-direction: column;
    gap: 10px;
    width: 200px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    display: none;
  }

  .menu.show {
    display: flex;
    transform: translateX(0);
  }

  .menu li {
    padding: 10px 0;
  }

  .menu li a {
    font-size: 1.1rem;
  }

  .floating-submenu {
    position: static;
    background-color: rgb(143, 116, 196);
    padding: 1px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 100%;
  }
}
@media (max-width: 576px) {
  .navbar {
    padding: 1px;
  }

  .logo img {
    height: 68px; /* Adjusted for mobile */
    width: 100px;
  }

  .menu a {
    padding: 8px 10px;
    font-size: 1rem; /* Slightly smaller text */
  }

  .menu {
    font-size: 0.8rem; /* Smaller text for mobile */
  }
}
