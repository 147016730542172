 /* Main Career section container */
.career {
    background-color: rgb(7, 153, 250);
    padding: 60px;
    border-radius: 5px;
   }
  
  /* Heading styles */
  .career p {
    color: rgb(243, 243, 243);
    font-size: 1rem;
     line-height: 1.3;
    max-width: 1200px;
    margin: 0 auto;
.csv-text1 {
  margin: 20px auto;
  max-width: 1100px;
  padding: 20px;
  line-height: 1.7;
}

.csv-text1 h1 {
  font-size: 2em;
  color: #0077b6;
  margin-bottom: 10px;
  margin: 20px auto;
  max-width: 1500px;
  background-color: #eeeeee18;
  padding: 2px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.csv-text1 ul {
  list-style-type: disc; /* Default bullet points */
  padding-left: 20px; /* Add padding to the left for proper indentation */
  margin-left: 20px;
  line-height: 1.7;
  font-size: 1em;
}

.csv-text1 li {
  font-size: 1.1em;
  margin-bottom: 10px; /* Space between list items */
  color: #333; /* Text color */
  text-align: left;
}

.csv-text1 h3 {
  font-size: 1.1em;
  color: #333;
  text-align: justify;
  margin-left: 20px;
  margin: 2px auto;
  max-width: 1100px;
  background-color: #f3f3f3;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* Media query for small screens (mobile) */
@media only screen and (max-width: 600px) {
  .csv-text1 h1, 
  .csv-text1 h3, 
  .csv-text1 li {
    font-size: 1.5em;
    padding: 10px;
    max-width: 90%;
  }

  .csv-text1 ul {
    padding-left: 10px; /* Reduced padding for small screens */
  }

  .csv-text1 li {
    font-size: 1.2em;
  }
}

/* Media query for tablets */
@media only screen and (max-width: 1024px) {
  .csv-text1 h1, 
  .csv-text1 h3 {
    font-size: 1.8em;
    max-width: 95%;
  }
}

/* Media query for large screens (desktops) */
@media only screen and (min-width: 1024px) {
  .csv-text1 h1, 
  .csv-text1 h3 {
    font-size: 2.5em;
    max-width: 1500px;
  }
}

  
  }
  
  /* Responsive design for large desktops and laptops */
  @media (min-width: 1200px) {
    .career p {
      font-size: 1.4rem;
      line-height: 1.8;
    }
  }
  
  /* Responsive design for tablets (max-width: 768px) */
  @media (max-width: 768px) {
    .career {
      padding: 40px;
    }
  
    .career p {
      font-size: 1.2rem;
      line-height: 1.5;
      max-width: 700px;
    }
  }
  
  /* Responsive design for small tablets and large smartphones (max-width: 600px) */
  @media (max-width: 600px) {
    .career {
      padding: 30px;
    }
  
    .career h1 {
      font-size: 1.2rem;
      line-height: 1.4;
      max-width: 550px;
    }
  }
  
  /* Responsive design for mobile devices (max-width: 480px) */
  @media (max-width: 480px) {
    .career {
      padding: 20px;
    }
  
    .career h1 {
      font-size: 1.2rem;
      line-height: 1.3;
      max-width: 100%;
    }
  }
  
  /* Extra small devices (max-width: 320px) */
  @media (max-width: 320px) {
    .career {
      padding: 15px;
    }
  
    .career h1 {
      font-size: 1.1rem;
      line-height: 1.2;
    }
  }
  