.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
}

/* Header section with background image */
img.about-image {
  max-width: 100%;
  height: 500px; /* Adjusted height */
  border-radius: 8px;
  margin-bottom: 20px;
  object-fit: cover; /* Ensures image doesn't distort */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* Subtle shadow for effect */
  width: 1500px;
}

/* Default styles for larger screens (e.g., desktops, laptops) */
.background-about-image {
  background-image: url("../../images/about.jpg");
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents repeating of the image */
  width: 100%; /* Full width */
  height: 400px; /* Height for larger screens */
  /* position: relative; Required for positioning content inside it */
}

.about_main {
  position: absolute; /* Position it inside the background */
  top: 50%; /* Adjust positioning to center it */
  left: 50%;
  transform: translate(-50%, -50%);
  color: aliceblue;
  font-size: 3rem; /* Font size for larger screens */
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 2px 2px 5px rgba(27, 27, 27, 0.4);
}

/* Media query for tablets (portrait) */
@media (max-width: 768px) {
  .background-about-image {
    height: 300px; /* Adjust height for smaller screens */
  }

  .about_main {
    font-size: 2.5rem; /* Slightly smaller font size */
  }
}

/* Media query for phones (portrait) */
@media (max-width: 480px) {
  .background-about-image {
    height: 250px; /* Smaller height for phones */
  }

  .about_main {
    font-size: 2rem; /* Smaller font size for phones */
    letter-spacing: 3px; /* Adjust letter spacing */
  }
}

#about-h1 {
  position: relative;
  position-visibility: inherit;
  top: -300px;
  color: aliceblue;
  font-size: 4rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 2px 2px 5px rgba(27, 27, 27, 0.4);
  z-index: 1;
}

/* Main content text */
.about-text h1 {
  font-size: 2.6rem;
  margin-bottom: 20px;
  color: #1974eb;
  font-weight: 700;
  margin-top: -65px;
}

.about-text p {
  font-size: 20px;
  line-height: 1=2;
  color: #141414;
  padding: 0 20px;
  max-width: 1100px; /* Limit paragraph width for readability */
  text-align: justify;
  font-weight: bold;
}

/* Value Proposition section */
.valu,
.partner {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  margin: 20px 0;
  max-width: 1064px;
  box-shadow: 0 4px 15px rgba(71, 66, 66, 0.1);
}

.valu h3,
.partner h3 {
  margin-top: 10px;
  font-size: 1.2rem;
  font-style: bold;
  color: #080808;
  line-height: 1.6;
  text-align: justify;
}
.iso h1 {
  padding-inline: 30px;
  color: #000000;
  background-color: aqua;
  border-radius: 10px;
}

/* ISO images side by side with spacing and zoom effect */
.iso-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 40px; /* Spacing between the images */
  flex-wrap: wrap;
}

.iso-container img {
  max-width: 250px;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 1.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.iso-container img:hover {
  transform: scale(1.29);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Add shadow on hover */
}

/* Zoom effect on image click */
.iso-container img:active {
  transform: scale(1.9); /* Larger zoom on click */
  z-index: 999;
  position: relative;
}

/* Media Queries for Responsive Design */
@media (max-width: 1200px) {
  img.about-image {
    height: 500px; /* Smaller height for medium screens */
  }

  .about-text h1 {
    font-size: 2.4rem;
  }

  .about-text p {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .about-container {
    padding: 15px;
  }

  img.about-image {
    height: 400px;
  }

  #about-h1 {
    top: -200px;
    font-size: 2.5rem;
  }

  .about-text h1 {
    font-size: 2rem;
  }

  .about-text p {
    font-size: 1rem;
    padding: 0 10px;
  }

  .iso-container {
    flex-direction: column;
    gap: 15px;
  }

  .iso-container img {
    max-width: 80%;
    height: auto;
  }
}

@media (max-width: 480px) {
  img.about-image {
    height: 300px;
  }

  #about-h1 {
    top: -150px;
    font-size: 2rem;
  }

  .about-text h1 {
    font-size: 1.8rem;
  }

  .about-text p {
    font-size: 0.9rem;
  }

  .iso-container img {
    max-width: 90%;
  }
}
