.overview {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf9f9;
}
.overview_main,
.overview_name,
.contrib_head {
  padding: 30px 50px;
  font-weight: 1000;
  font-size: 32px;
  letter-spacing: 1.5px;
}
.overview_name,
.tag_line {
  font-size: 2rem;
  text-align: center;
  letter-spacing: 0px;
  padding: 10px;
}
.overview_desc {
  text-align: center;
  padding: 2px 200px;
  color: rgba(158, 128, 128, 0.513);
  font-size: 16px;
  line-height: 30px;
}
.learn_more_btn {
  background-color: #4f92f6;
  border-radius: 25px;
  padding: 10px 20px;
  color: whitesmoke;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}
.contrib_head {
  font-size: 28px;
  font-weight: bolder;
  letter-spacing: 1px;
  padding-bottom: 0px;
}
.tag_line {
  font-size: 40px;
  font-weight: 900;
}
/* Media Query for 412px Width and 915px Height */
@media (max-width: 412px) and (max-height: 915px) {
  .home {
    height: 600px; /* Adjust height for smaller screens */
    background-position: center;
    background-size: cover;
  }

  .maindiv {
    padding: 20px; /* Add padding for smaller screens */
  }

  .h3 {
    margin-left: 0;
    height: auto;
    justify-content: center;
    text-align: center;
    font-size: 1.4rem; /* Smaller font size */
  }

  .h1 {
    height: auto;
    display: block;
    text-align: center;
    margin-top: 0;
    font-size: 1rem; /* Adjusted font size */
    color: rgb(70, 135, 219);
  }

  .h2 {
    height: auto;
    display: block;
    text-align: center;
    margin-top: 10px; /* Adjust margin for better spacing */
    font-size: 2rem; /* Adjusted font size */
    color: rgb(7, 33, 59);
  }

  .overview {
    flex-direction: column;
    padding: 20px; /* Reduce padding */
  }

  .overview_main,
  .overview_name,
  .contrib_head {
    padding: 10px;
    font-size: 1.4rem; /* Reduce font size */
    text-align: center; /* Center-align text */
  }

  .overview_desc {
    padding: 2px 20px; /* Adjust padding for better spacing */
    font-size: 14px; /* Reduce font size */
    line-height: 1.5rem; /* Adjust line height */
  }

  .learn_more_btn {
    padding: 8px 16px;
    font-size: 16px; /* Adjust button font size */
  }

  .service_div {
    height: auto; /* Auto height for better responsiveness */
  }

  .imagesin_over {
    padding: 20px;
    display: block; /* Stack items vertically */
  }

  .images_div {
    height: 300px; /* Reduce image size for mobile */
    background-size: cover;
  }

  .images_desc {
    font-size: 1rem; /* Adjust font size */
    padding-top: 20px;
  }
  .overview-image {
    text-align: center;
    margin: 20px 0;
  }

  .overview-image img {
    width: 100%;
    max-width: 1100px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media (width>400px) {
  .service_div {
    height: 1500px;
    padding: 50px 0px;
  }
  .imagesin_over {
    padding: 80px 150px;
    height: 1500px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
.imagesin_over {
  padding: 80px 150px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service_div {
  height: 800px;
}

.images_desc {
  grid-row: 4/5;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 900;
  color: #2470ff;
  padding-top: 50px;
}
