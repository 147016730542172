/* Global settings */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f9;
  color: #333;
}

.candq-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
  flex-direction: row-reverse; /* This puts the image on the right */
}

.candq-image {
  flex: 1;
  max-width: 70%;
}

.candq-image img {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.candq-text {
  flex: 1;
  padding-left: 20px;
}

.candq-text h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #03032b;
  font-weight: 700;
}

.candq-text h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #0d83f8;
}

.candq-text li {
  font-size: 17px;
  line-height: 1.3;
  color: #030303;
  font-weight: bold;
}

.candq-container1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
}

.candq1-image {
  flex: 1;
  max-width: 80%;
}

.candq1-image img {
  width: 100%;
  height: 400px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

.candq-text2 {
  flex: 1;
  padding-left: 20px;
}

.candq-text2 h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #03032b;
  font-weight: bold;
}

.candq-text2 h2 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #0d83f8;
}

.candq-text2 li {
  font-size: 17px;
  line-height: 1.6;
  color: #030303;
  font-weight: bold;
}

.candq-text2 h4 br {
  margin-bottom: 10px; /* Spacing between bullet points */
}

/* Text section styling */
.candq1,
.cand2,
.cand3,
.cand4 {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgb(236, 236, 236);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
.cand5 {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: rgb(240, 240, 240);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
.cand5 h1 {
  font-size: 1.8em;
  color: #0056b3;
  margin-bottom: 10px;
}
.cand5 li {
  font-size: 17px;
  color: rgb(18, 17, 19);
}

.candq1 h2,
.cand2 h2,
.cand3 h2,
.cand4 h2 {
  font-size: 1.8em;
  color: #0056b3;
  margin-bottom: 10px;
}

.candq1 li,
.cand2 h4,
.cand3 h4,
.cand4 h4 {
  font-size: 1.2em;
  line-height: 1.6;
  color: #1d1515;
  text-align: justify;
  margin-bottom: 0;
}

.candq1 h4::before,
.cand2 h4::before,
.cand3 h4::before,
.cand4 h4::before {
  color: #ff0000;
  font-size: 1.5em;
  margin-right: 8px;
}

/* Responsive image hover effect */
.candq-image img:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease-in-out;
}

/* Responsive styling */
@media (max-width: 768px) {
  .candq-image img {
    width: 90%;
  }

  .candq1 h2,
  .cand2 h2,
  .cand3 h2,
  .cand4 h2 {
    font-size: 1.5em;
  }

  .candq1 li,
  .cand2 h4,
  .cand3 h4,
  .cand4 h4 {
    font-size: 1em;
  }

  .candq1,
  .cand2,
  .cand3,
  .cand4 {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .candq-image img {
    width: 95%;
  }

  .candq1 h2,
  .cand2 h2,
  .cand3 h2,
  .cand4 h2 {
    font-size: 1.3em;
  }

  .candq1 h4,
  .cand2 h4,
  .cand3 h4,
  .cand4 h4 {
    font-size: 0.95em;
  }
}
@media screen and (max-width: 768px) {
  .candq-container {
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
  }

  .candq-text {
    padding-left: 0;
  }
  .candq-image,
  .candq-content {
    max-width: 100%;
  }

  .candq-content {
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .candq-container1 {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .candq1-image {
    max-width: 90%; /* Image takes more space on small screens */
  }

  .candq-text2 {
    padding-left: 0;
    text-align: center;
  }

  .candq-text2 h1,
  .candq-text2 h2,
  .candq-text2 li {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .candq-text2 h1 {
    font-size: 2rem;
  }

  .candq-text2 h2 {
    font-size: 1.5rem;
  }

  .candq-text2 li {
    font-size: 1rem;
  }
}
