 /* Excel component styles */

/* Styling for the image container */
.excel-image {
    text-align: center;
    margin-top: 20px;
  }
  
  .excel-image img {
    max-width: 100%;
    height: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
    width: 1100px;
  }
  
  /* General text styling */
  .excel1-text, .excel2-text, .excel3-text, .excel4-text, .excel5-text, .excel6-text {
    margin: 10px auto;
    padding: 10px;
    max-width: 1200px;
    line-height: 0.0;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #e7e7e744;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Specific styling for headings */
  .excel1-text h1 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 10px;
    color: #091826;
  }
  
 .excel1-text h2 {
    font-size: 2rem;
    color: #2d85c0;
    margin-bottom: 10px;
  }
  .excel2-text h2 {
    font-size: 2rem;
    color: #2d85c0;
    margin-bottom: 10px;
  }
  .excel3-text h2 {
    font-size: 2rem;
    color: #2d85c0;
    margin-bottom: 10px;
  }
  .excel4-text h2 {
    font-size: 2rem;
    color: #2d85c0;
    margin-bottom: 10px;
  } 
  .excel5-text h2 {
    font-size: 2rem;
    color: #2d85c0;
    margin-bottom: 10px;
  } 
  .excel6-text h2 {
    font-size: 2rem;
    color: #2d85c0;
    margin-bottom: 10px;
  }
  /* Specific styling for paragraphs */
  .excel1-text, .excel2-text, .excel3-text, .excel4-text, .excel5-text, .excel6-text h4 {
    font-size: 1.2rem;
    color: #2c2525;
    line-height: 1.5; 
      }
  
  /* Add icons to headings */
  .excel1-text h4:before,
  .excel2-text h4:before,
  .excel3-text h4:before,
  .excel4-text h4:before,
  .excel5-text h4:before,
  .excel6-text h4:before {
     font-size: 1.2rem;
    margin-right: 10px;
    color: #e74c3c;
  }
  
  /* Responsive media query */
  @media screen and (max-width: 768px) {
    .excel1-text h1 {
      font-size: 2rem;
    }
  
   
  
    .excel1-text, .excel2-text, .excel3-text, .excel4-text, .excel5-text, .excel6-text {
      padding: 15px;
    }
  }
  